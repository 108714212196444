import React from 'react'
import Helmet from 'react-helmet'
import GlobalStyle from './GlobalStyles'
import ShareCard from '../images/og-image.jpg'

const GlobalHelmet = ({title, location}) => (
  <>
    <Helmet
      title={`${title}${ location ? ' - ' + location : ''}`}
      meta={[
        { name: 'description', content: `Element 5 Spa located in Saint John and Moncton, New Brunswick offers spa treatments and packages for men and women.` },
        { name: 'keywords', content: `Element 5 Spa Saint John, Element 5 Spa Moncton, Element 5 Spa, Spa New Brunswick, Spa Treatments New Brunswick, Spa Saint John, Spa Moncton, Spa Treatments Saint John, Spa Treatments Moncton, Aveda Spa, Aveda Products, Massage Therapy Saint John, Massage Therapy Moncton, Massage Therapy New Brunswick, Reflexology New Brunswick, Reflexology Saint John, Reflexology Moncton, Acupuncture Saint John, Acupuncture Moncton, Acupuncture New Brunswick, Aveda Facials, Pedicures Saint John, Pedicures Moncton, Manicures Saint John, Manicures Moncton, Hair Removal Saint John, Hair Removal Moncton, Spa Packages Saint John, Spa Packages Moncton, Spa Packages New Brunswick, Element 5 Market Square, Market Square Saint John New Brunswick`},
        { name: 'og:image', content: ShareCard},
        { name: 'viewport', content: 'initial-scale=1, viewport-fit=cover'},
        { name: 'google-site-verification', content: 'BepmNUupddgxk1Zy0WgWVWkS81CsKl2sdhIsjbI2_Z8'}
      ]}
      link={[
        { href: 'https://use.typekit.net/rie4ooa.css', rel: 'stylesheet' }
      ]}
    >
      <html lang="en" />
    </Helmet>
    <GlobalStyle />
  </> 
)

export default GlobalHelmet