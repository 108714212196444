import reset from 'styled-reset'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  ${reset}
  html, body {
    font-size: 18px;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.6
  }
  body {
    text-rendering: optimizeLegibility
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
  }
  a {
    transition: all 0.2s linear;
    &:hover {
      opacity: 0.7;
    }
  }
  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }
  b {
    font-weight: 700
  }
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h1 {
    font-family: abril-fatface, serif;
    font-size: 2.066666667em;
    text-align: center;
    text-transform: uppercase;
    padding: 1em;
    background: white;
    color: #484C4D
    font-weight: bold;
    letter-spacing: 4px;
  }
  p {
    margin-bottom: 1em;
  }
  small {
    font-size: 0.7em;
    font-style: italic;
  }
  sup {
    vertical-align: super;
    font-size: smaller;
  }
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }

  .react-tabs__tab--selected {
    border-bottom: 2px solid #071d22;
  }

  .ril-outer .ril__caption {
    justify-content: center;
  }
`

export default GlobalStyle
